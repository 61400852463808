import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import Switch from "react-switch";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import "chartjs-adapter-date-fns";
import "./CellData.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  annotationPlugin
);

function CellData() {
  const [cellData, setCellData] = useState([]);
  const [relayStatus, setRelayStatus] = useState("OFF");
  const [relay, setRelay] = useState("OFF");
  const [numDataPoints, setNumDataPoints] = useState(10);
  const [fastDischargeMessage, setFastDischargeMessage] = useState("");
  const [alertLogs, setAlertLogs] = useState([]);
  const [seenAlerts, setSeenAlerts] = useState(new Set());
  const [submittedDataPoints, setSubmittedDataPoints] = useState(10);
  // const ws = useRef(null);

  // useEffect(() => {
  //   // Initialize WebSocket connection
  //   ws.current = new WebSocket("wss:www.transcent.in/main");

  //   ws.current.onopen = () => {
  //     console.log("WebSocket connected");
  //   };

  //   ws.current.onmessage = (event) => {
  //     try {
  //       const message = JSON.parse(event.data);

  //       switch (message.type) {
  //         case "cellData":
  //           handleNewCellData(message.data);
  //           break;

  //         case "relay":
  //           setRelay(message.relay);
  //           break;

  //         case "status":
  //           setRelayStatus(message.status);
  //           break;

  //         case "alert":
  //           if (!seenAlerts.has(message.alert)) {
  //             setAlertLogs((prevAlerts) => [...prevAlerts, message.alert]);
  //             setSeenAlerts((prevSeen) => new Set(prevSeen).add(message.alert));
  //           }
  //           break;

  //         default:
  //           console.error("Unknown message type:", message.type);
  //       }
  //     } catch (error) {
  //       console.error("Error parsing WebSocket message:", error);
  //     }
  //   };

  //   ws.current.onclose = () => {
  //     console.log("WebSocket disconnected");
  //   };

  //   ws.current.onerror = (error) => {
  //     console.error("WebSocket error:", error);
  //   };

  //   return () => {
  //     if (ws.current) {
  //       ws.current.close();
  //     }
  //   };
  // }, [seenAlerts]);

  // const handleNewCellData = (newData) => {
  //   setCellData((prevData) => {
  //     const updatedData = [...prevData, newData];
  //     return updatedData.slice(-numDataPoints); // Keep last numDataPoints data points
  //   });

  //   const dischargeThreshold = 2.0; // Example threshold, adjust as needed
  //   if (newData.overall_current > dischargeThreshold) {
  //     setFastDischargeMessage("Fast discharge detected!");
  //     setAlertLogs((prevAlerts) => [...prevAlerts, "Fast discharge detected!"]);
  //     setSeenAlerts((prevSeen) =>
  //       new Set(prevSeen).add("Fast discharge detected!")
  //     );
  //   } else {
  //     setFastDischargeMessage("");
  //   }
  // };

  // const handleToggleChange = (checked) => {
  //   const newState = checked ? "ON" : "OFF";
  //   if (ws.current && ws.current.readyState === WebSocket.OPEN) {
  //     ws.current.send(JSON.stringify({ type: "relay", relay: newState }));
  //     setRelay(newState); // Update relay state locally based on user interaction
  //   } else {
  //     console.error("WebSocket is not open");
  //   }
  // };

  const ws1 = useRef(null);
  const ws2 = useRef(null);

  useEffect(() => {
    // Initialize WebSocket connections
    ws1.current = new WebSocket(process.env.wss1);
    ws2.current = new WebSocket(process.env.wss2);

    // Setup WebSocket 1
    ws1.current.onopen = () => {
      console.log("WebSocket 1 connected");
    };
    ws1.current.onmessage = (event) => {
      handleWebSocketMessage(event, "ws1");
    };
    ws1.current.onclose = () => {
      console.log("WebSocket 1 disconnected");
    };
    ws1.current.onerror = (error) => {
      console.error("WebSocket 1 error:", error);
    };

    // Setup WebSocket 2
    ws2.current.onopen = () => {
      console.log("WebSocket 2 connected");
    };
    ws2.current.onmessage = (event) => {
      handleWebSocketMessage(event, "ws2");
    };
    ws2.current.onclose = () => {
      console.log("WebSocket 2 disconnected");
    };
    ws2.current.onerror = (error) => {
      console.error("WebSocket 2 error:", error);
    };

    return () => {
      // Close WebSocket connections
      if (ws1.current) ws1.current.close();
      if (ws2.current) ws2.current.close();
    };
  }, [seenAlerts]);

  const handleWebSocketMessage = (event, wsSource) => {
    try {
      const message = JSON.parse(event.data);

      switch (message.type) {
        case "cellData":
          handleNewCellData(message.data, wsSource);
          break;

        case "relay":
          setRelay(message.relay);
          break;

        case "status":
          setRelayStatus(message.status);
          break;

        case "alert":
          if (!seenAlerts.has(message.alert)) {
            setAlertLogs((prevAlerts) => [...prevAlerts, message.alert]);
            setSeenAlerts((prevSeen) => new Set(prevSeen).add(message.alert));
          }
          break;

        default:
          console.error("Unknown message type:", message.type);
      }
    } catch (error) {
      console.error("Error parsing WebSocket message:", error);
    }
  };

  const handleNewCellData = (newData, wsSource) => {
    console.log(`Received cell data from ${wsSource}`);
    setCellData((prevData) => {
      const updatedData = [...prevData, newData];
      return updatedData.slice(-numDataPoints); // Keep last numDataPoints data points
    });

    const dischargeThreshold = 2.0; // Discharge threshold
    const overchargeThreshold = 4.0; // Overcharge threshold

    // Check each cell's voltage for discharge or overcharge
    if (newData.cell1_voltage < dischargeThreshold) {
      triggerAlert(
        `Cell 1 voltage below ${dischargeThreshold}V (Discharge detected)`
      );
    } else if (newData.cell1_voltage > overchargeThreshold) {
      triggerAlert(
        `Cell 1 voltage above ${overchargeThreshold}V (Overcharge detected)`
      );
    }

    if (newData.cell2_voltage < dischargeThreshold) {
      triggerAlert(
        `Cell 2 voltage below ${dischargeThreshold}V (Discharge detected)`
      );
    } else if (newData.cell2_voltage > overchargeThreshold) {
      triggerAlert(
        `Cell 2 voltage above ${overchargeThreshold}V (Overcharge detected)`
      );
    }

    if (newData.cell3_voltage < dischargeThreshold) {
      triggerAlert(
        `Cell 3 voltage below ${dischargeThreshold}V (Discharge detected)`
      );
    } else if (newData.cell3_voltage > overchargeThreshold) {
      triggerAlert(
        `Cell 3 voltage above ${overchargeThreshold}V (Overcharge detected)`
      );
    }

    if (newData.cell4_voltage < dischargeThreshold) {
      triggerAlert(
        `Cell 4 voltage below ${dischargeThreshold}V (Discharge detected)`
      );
    } else if (newData.cell4_voltage > overchargeThreshold) {
      triggerAlert(
        `Cell 4 voltage above ${overchargeThreshold}V (Overcharge detected)`
      );
    }
  };

  // Utility function to trigger alerts and update the logs
  const triggerAlert = (alertMessage) => {
    setFastDischargeMessage(alertMessage);
    setAlertLogs((prevAlerts) => [...prevAlerts, alertMessage]);
    setSeenAlerts((prevSeen) => new Set(prevSeen).add(alertMessage));
  };

  const handleToggleChange = (checked) => {
    const newState = checked ? "ON" : "OFF";
    if (ws1.current && ws1.current.readyState === WebSocket.OPEN) {
      ws1.current.send(JSON.stringify({ type: "relay", relay: newState }));
      setRelay(newState); // Update relay state locally based on user interaction
    } else {
      console.error("WebSocket 1 is not open");
    }
  };

  const handleSubmitDataPoints = () => {
    setSubmittedDataPoints(numDataPoints);
  };

  const handleDataPointsChange = (event) => {
    const value =
      event.target.value === "" ? "" : parseInt(event.target.value, 10);
    if (value === "" || (!isNaN(value) && value >= 0)) {
      setNumDataPoints(value);
    }
  };

  const labels = cellData.map((item) =>
    item.createdAt ? new Date(item.createdAt) : "N/A"
  );
  const cell1VoltageData = cellData.map((item) => item.cell1_voltage);
  const cell2VoltageData = cellData.map((item) => item.cell2_voltage);
  const cell3VoltageData = cellData.map((item) => item.cell3_voltage);
  const cell4VoltageData = cellData.map((item) => item.cell4_voltage);
  const overallCurrentData = cellData.map((item) => item.overall_current);
  const overallTemperatureData = cellData.map(
    (item) => item.overall_temperature
  );

  const voltageData = {
    labels,
    datasets: [
      {
        label: "Cell 1 Voltage (V)",
        data: cell1VoltageData,
        borderColor: "rgba(120, 130, 130, 1)",
        backgroundColor: "rgba(120, 130, 130, 0.2)",
        borderWidth: 2,
        yAxisID: "y-voltage",
      },
      {
        label: "Cell 2 Voltage (V)",
        data: cell2VoltageData,
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderWidth: 2,
        yAxisID: "y-voltage",
      },
      {
        label: "Cell 3 Voltage (V)",
        data: cell3VoltageData,
        borderColor: "rgba(255, 206, 86, 1)",
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        borderWidth: 2,
        yAxisID: "y-voltage",
      },
      {
        label: "Cell 4 Voltage (V)",
        data: cell4VoltageData,
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderWidth: 2,
        yAxisID: "y-voltage",
      },
    ],
  };

  const currentData = {
    labels,
    datasets: [
      {
        label: "Overall Current (A)",
        data: overallCurrentData,
        borderColor: "rgba(255, 159, 64, 1)",
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderWidth: 2,
        yAxisID: "y-current",
      },
    ],
  };

  const temperatureData = {
    labels,
    datasets: [
      {
        label: "Overall Temperature (°C)",
        data: overallTemperatureData,
        borderColor: "rgba(153, 102, 255, 1)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderWidth: 2,
        yAxisID: "y-temperature",
      },
    ],
  };

  const voltageOptions = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "minute",
          stepSize: 1,
          displayFormats: {
            minute: "HH:mm",
            hour: "HH:mm",
          },
          min: new Date(new Date().setMinutes(new Date().getMinutes() - 60)),
          max: new Date(),
        },
        title: {
          display: true,
          text: "Time",
        },
      },
      "y-voltage": {
        title: {
          display: true,
          text: "Voltage (V)",
        },
        position: "left",
        min: 2,
        max: 4,
        ticks: {
          stepSize: 0.2,
        },
      },
    },
    plugins: {
      annotation: {
        annotations: {
          line1: {
            type: "line",
            yMin: 2.2,
            yMax: 2.2,
            borderColor: "red",
            borderWidth: 2,
            label: {
              content: "Over Discharge",
              enabled: true,
              position: "center",
            },
          },
          line2: {
            type: "line",
            yMin: 3.65,
            yMax: 3.65,
            borderColor: "red",
            borderWidth: 2,
            label: {
              content: "Over Charge",
              enabled: true,
              position: "center",
            },
          },
        },
      },
    },
  };

  const currentOptions = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "minute",
          stepSize: 1,
          displayFormats: {
            minute: "HH:mm",
            hour: "HH:mm",
          },
          min: new Date(new Date().setMinutes(new Date().getMinutes() - 60)),
          max: new Date(),
        },
        title: {
          display: true,
          text: "Time",
        },
      },
      "y-current": {
        title: {
          display: true,
          text: "Current (A)",
        },
        position: "left",
        min: -2,
        max: 3,
        ticks: {
          stepSize: 0.5,
          callback: function (value) {
            return value.toFixed(1); // Format tick labels as required
          },
        },
      },
    },
  };

  const temperatureOptions = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "minute",
          stepSize: 1,
          displayFormats: {
            minute: "HH:mm",
            hour: "HH:mm",
          },
          min: new Date(new Date().setMinutes(new Date().getMinutes() - 60)),
          max: new Date(),
        },
        title: {
          display: true,
          text: "Time",
        },
      },
      "y-temperature": {
        title: {
          display: true,
          text: "Temperature (°C)",
        },
        min: -10,
        max: 60,
        ticks: {
          stepSize: 5,
        },
      },
    },
  };

  return (
    <div className="cell-data-container">
      <div className="header">
        <h1>Cell Data</h1>
        <div className="relay-switch">
          <span>Relay Button:</span>
          <Switch
            onChange={handleToggleChange}
            checked={relay === "ON"}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#00ff00"
            offColor="#ff0000"
            height={20}
            width={48}
            handleDiameter={24}
          />
        </div>
        <div className="relay-data">
          <span className="relay-status">Relay Status:</span>
          <p>{relayStatus}</p> {/* Displaying relay state as text */}
        </div>
      </div>

      <div className="data-points-input">
        <label>
          Number of Data Points:
          <input
            type="number"
            value={numDataPoints}
            onChange={handleDataPointsChange}
          />
        </label>
        <button onClick={handleSubmitDataPoints}>Submit</button>
      </div>

      {fastDischargeMessage && (
        <div className="fast-discharge-message">{fastDischargeMessage}</div>
      )}

      <div>
        <h2 className="alerts-box">Alerts</h2>
        <ul>
          {alertLogs.slice(-3).map((alert, index) => (
            <li key={index}>{alert}</li>
          ))}
        </ul>
      </div>
      <h2 className="chart-title">Voltage Data</h2>
      <div className="charts">
        <div className="chartv voltage-chart">
          <Line data={voltageData} options={voltageOptions} />
        </div>

        <div className="side-by-side-charts">
          <div className="chart">
            <h2 className="chart-title">Current Data</h2>
            <Line data={currentData} options={currentOptions} />
          </div>

          <div className="chart">
            <h2 className="chart-title">Temperature Data</h2>
            <Line data={temperatureData} options={temperatureOptions} />
          </div>
        </div>
      </div>

      <table className="styled-table">
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Battery Voltage</th>
            <th>Cell 1 Voltage</th>
            <th>Cell 2 Voltage</th>
            <th>Cell 3 Voltage</th>
            <th>Cell 4 Voltage</th>
            <th>Current</th>
            <th>Temperature</th>
          </tr>
        </thead>
        <tbody>
          {cellData
            .slice()
            .reverse()
            .map((item, index) => (
              <tr key={index}>
                <td>
                  {item.createdAt
                    ? new Date(item.createdAt).toLocaleString()
                    : "N/A"}
                </td>
                <td>{item.battery_voltage} V</td>
                <td>{item.cell1_voltage} V</td>
                <td>{item.cell2_voltage} V</td>
                <td>{item.cell3_voltage} V</td>
                <td>{item.cell4_voltage} V</td>
                <td>{item.overall_current} A</td>
                <td>{item.overall_temperature} °C</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default CellData;
