import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = ({ onLogout }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear authentication token and user data
    localStorage.removeItem("userToken");
    localStorage.removeItem("username");

    // Notify parent component to update authentication state
    onLogout();

    // Redirect to login page
    navigate("/login");
  }, [navigate, onLogout]);

  return null; // This component doesn't need to render anything
};

export default Logout;
