import React from "react";
import "./lithium_batteries.css";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <a className="navbar-brand" href="#">
          [TABS]
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="#overview">
                Overview
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#products">
                Products
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Solutions
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a className="dropdown-item" href="#custom-batteries">
                  Customized Lithium Batteries
                </a>
                <a className="dropdown-item" href="#smart-battery">
                  Smart Feature Implementations
                </a>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#services">
                Services
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <main>
        <section
          id="hero-overview"
          className="py-5"
          style={{ backgroundColor: "#007bff" }}
        >
          <div
            className="container text-center text-white"
            style={{ paddingTop: "10vh", paddingBottom: "10vh" }}
          >
            <div className="hero-overview-content mb-5">
              <h1>Transcent: Advanced Battery Solutions</h1>
              <p>
                At the forefront of sustainable energy storage, we specialize in
                technology-driven solutions, customizations, and comprehensive
                services. Explore our key offerings:
              </p>
            </div>
            <div className="overview-content bg-light text-dark rounded p-4">
              <div className="row text-center">
                <div className="col-md-4">
                  <i className="bi bi-cloud" style={{ fontSize: "2rem" }}></i>
                  <h4 className="mt-3">IoT Connectivity</h4>
                  <p>
                    Experience real-time monitoring and control with IoT-enabled
                    batteries, transforming your energy management into an
                    integrated ecosystem.
                  </p>
                </div>
                <div className="col-md-4">
                  <i
                    className="bi bi-code-slash"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <h4 className="mt-3">API Integration</h4>
                  <p>
                    Seamlessly integrate our batteries into your systems with
                    robust API support, managing and monitoring your energy
                    solutions efficiently.
                  </p>
                </div>
                <div className="col-md-4">
                  <i className="bi bi-cpu" style={{ fontSize: "2rem" }}></i>
                  <h4 className="mt-3">ML / AI Powered</h4>
                  <p>
                    Optimize efficiency and longevity with our AI and ML-powered
                    batteries, offering predictive maintenance and intelligent
                    decision-making.
                  </p>
                </div>
                <div className="col-md-4">
                  <i className="bi bi-toggles" style={{ fontSize: "2rem" }}></i>
                  <h4 className="mt-3">Customized to Your Needs</h4>
                  <p>
                    Tailored energy solutions designed to fit your specific
                    requirements, ensuring optimal performance and reliability.
                  </p>
                </div>
                <div className="col-md-4">
                  <i
                    className="bi bi-shield-check"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <h4 className="mt-3">Industry Certified (AIS-156)</h4>
                  <p>
                    Our batteries meet AIS-156 standards, ensuring quality,
                    safety, and reliability.
                  </p>
                </div>
                <div className="col-md-4">
                  <i className="bi bi-tools" style={{ fontSize: "2rem" }}></i>
                  <h4 className="mt-3">Fully Serviced</h4>
                  <p>
                    Comprehensive support throughout your battery's lifecycle,
                    from design to decommissioning.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="smart-battery" className="py-5 bg-light">
          <div className="container">
            <h2 className="text-center mb-4">
              Smart Battery Solutions: Powering the Future
            </h2>
            <p>
              At Transcent, we're at the forefront of intelligent energy
              management. Our smart batteries integrate cutting-edge technology
              to offer comprehensive solutions, ensuring efficiency,
              reliability, and seamless integration.
            </p>
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="feature-card">
                  <i
                    className="bi bi-gear-wide-connected"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <h3>AI &amp; ML powered batteries</h3>
                  <p>
                    Leverage AI and ML for intelligent energy systems to ensure
                    efficiency and reliability.
                  </p>
                  <ul>
                    <li>
                      AI-driven optimization for charging and discharging
                      cycles.
                    </li>
                    <li>Predictive maintenance to reduce downtime.</li>
                    <li>Adaptive learning to improve efficiency over time.</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-card">
                  <i
                    className="bi bi-bar-chart"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <h3>IoT and Real-time Analytics</h3>
                  <p>
                    Empower your energy systems with real-time monitoring and
                    detailed analytics.
                  </p>
                  <ul>
                    <li>Real-time monitoring for peak efficiency.</li>
                    <li>Detailed analytics for informed decision-making.</li>
                    <li>Data-driven insights to optimize energy usage.</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-card">
                  <i
                    className="bi bi-layers-half"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <h3>API Integration &amp; automation</h3>
                  <p>
                    Enjoy seamless connectivity through API support to ensure
                    your energy solutions are harmoniously integrated within
                    your tech ecosystem.
                  </p>
                  <ul>
                    <li>Robust API support for seamless system integration.</li>
                    <li>
                      Effective communication between battery systems and
                      existing infrastructure.
                    </li>
                    <li>
                      Swift automated responses, enhancing system reliability
                      and performance.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <p className="text-center mt-4">
              Contact us to explore how our intelligent battery systems can
              transform your energy management.
            </p>
          </div>
        </section>
        <section id="products" className="py-5">
          <div className="container">
            <h2 className="text-center mb-4">Our Product Range</h2>
            <p className="text-center mb-5">
              Discover our line of AIS 156 certified LiFePO4 batteries,
              specifically designed for electric two-wheelers. Each product is
              engineered for efficiency, durability, and high performance.
            </p>
            <div className="row">
              <div className="col-md-6 col-lg-3 mb-4">
                <div className="product-card">
                  <h5>48V 24Ah Battery</h5>
                  <p>
                    This battery offers a perfect balance of power and
                    efficiency for medium-range electric two-wheelers. It's
                    designed to provide reliable energy storage with a compact
                    form factor.
                  </p>
                  <a href="#contact" className="btn btn-primary">
                    Inquire Now
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-4">
                <div className="product-card">
                  <h5>48V 30Ah Battery</h5>
                  <p>
                    Ideal for those requiring more power without significantly
                    increasing size. It's an excellent choice for
                    high-performance electric scooters and bikes.
                  </p>
                  <a href="#contact" className="btn btn-primary">
                    Inquire Now
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-4">
                <div className="product-card">
                  <h5>60V 24Ah Battery</h5>
                  <p>
                    Designed for vehicles needing higher voltage, this battery
                    ensures a longer range and greater power, suitable for
                    advanced electric two-wheelers.
                  </p>
                  <a href="#contact" className="btn btn-primary">
                    Inquire Now
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-4">
                <div className="product-card">
                  <h5>72V 24Ah Battery</h5>
                  <p>
                    Our most powerful offering, perfect for high-end electric
                    motorcycles and scooters that demand superior performance
                    and longer range.
                  </p>
                  <a href="#contact" className="btn btn-primary">
                    Inquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="custom-batteries" className="py-5 bg-light">
          <div className="container">
            <h2 className="text-center mb-4">Customized Lithium Batteries</h2>
            <p>
              Experience precision and performance with our tailor-made battery
              solutions. Designed to meet your unique specifications, our
              customized batteries integrate seamlessly with your systems,
              ensuring optimal performance and reliability.
            </p>
            <div className="row">
              <div className="col-md-4">
                <h3>Space Customization</h3>
                <p>
                  Our batteries are designed to fit your space requirements
                  perfectly, maximizing energy storage efficiency within your
                  space constraints.
                </p>
              </div>
              <div className="col-md-4">
                <h3>Electrical Customization</h3>
                <p>
                  Electrical aspects like charging and discharging cutoffs, and
                  current capacities are tailored to meet your specific needs,
                  enhancing performance and longevity.
                </p>
              </div>
              <div className="col-md-4">
                <h3>Component Customization</h3>
                <p>
                  From specific components to enclosures and mounting systems,
                  every element is chosen to provide quality and reliability,
                  giving your device the edge it needs.
                </p>
              </div>
            </div>
            <p className="contact-call-to-action">
              Contact us to explore our custom lithium battery solutions
              tailored for your innovation.
            </p>
          </div>
        </section>
        <section id="automotive-batteries" className="py-5">
          <div className="container">
            <h2 className="text-center mb-4">
              Automotive Grade Batteries with AIS 156 Certification
            </h2>
            <p>
              Our batteries are AIS 156 certified, ensuring they meet stringent
              standards for automotive applications, embodying safety,
              reliability, and quality.
            </p>
            <div className="ais-grid">
              <div className="ais-item">
                <h3>What is AIS 156 Certification?</h3>
                <p>
                  <strong>AIS 156:</strong> The Gold Standard for Automotive
                  Batteries, outlining safety requirements for lithium-ion
                  batteries in electric vehicles.
                </p>
                <p>
                  <strong>Benchmark of Quality and Safety:</strong> Ensures
                  batteries are tested for thermal stability and safety,
                  offering reliability and trust.
                </p>
              </div>
              <div className="ais-item">
                <h3>Why Choose AIS 156 Certified Batteries?</h3>
                <p>
                  <strong>Enhanced Safety:</strong> Undergo thorough testing for
                  extreme conditions, ensuring operational safety.
                </p>
                <p>
                  <strong>Reliability for the Road Ahead:</strong> Designed for
                  longevity and consistent performance in electric vehicles.
                </p>
                <p>
                  <strong>Compliance and Peace of Mind:</strong> Compliance with
                  national safety regulations, offering confidence in our
                  products.
                </p>
              </div>
              <div className="ais-item">
                <h3>Our Commitment to Excellence</h3>
                <p>
                  <strong>Continuous Testing and Improvement:</strong>{" "}
                  Innovating to exceed standards, ensuring our products lead in
                  safety and performance.
                </p>
                <p>
                  <strong>Tailored for Automotive Needs:</strong> Optimized for
                  various automotive applications, offering high energy density
                  and fast charging capabilities.
                </p>
              </div>
            </div>
            <p>
              Contact us to learn more about our AIS 156 certified automotive
              battery solutions.
            </p>
          </div>
        </section>
        <section id="service-support" className="py-5">
          <div className="container">
            <h2 className="text-center mb-4">
              Comprehensive Service and Support
            </h2>
            <p>
              From development to decommissioning, our commitment extends beyond
              the sale. Transcent Labs offers full-spectrum services, ensuring
              that every phase of your battery's lifecycle is managed with
              precision and care.
            </p>
            <div className="row">
              <div className="col-md-4">
                <h3>Development</h3>
                <p>
                  Collaborative design and engineering processes ensure that
                  your custom battery solutions meet specific needs from the
                  ground up.
                </p>
              </div>
              <div className="col-md-4">
                <h3>Deployment</h3>
                <p>
                  Expert installation services for seamless integration, coupled
                  with operational guidance to optimize performance in specific
                  applications.
                </p>
              </div>
              <div className="col-md-4">
                <h3>Maintenance</h3>
                <p>
                  Regular check-ups, monitoring, and preventive maintenance
                  ensure your systems operate at peak performance, prolonging
                  product life and averting issues.
                </p>
              </div>
              <div className="col-md-4">
                <h3>Repair</h3>
                <p>
                  Quick and efficient problem resolution, coupled with easy
                  access to necessary parts, minimizes downtime and ensures your
                  operations run smoothly.
                </p>
              </div>
              <div className="col-md-4">
                <h3>Decommissioning</h3>
                <p>
                  Safe and environmentally responsible retirement of products,
                  ensuring components are disposed of or recycled responsibly.
                </p>
              </div>
            </div>
            <p>
              Contact us to discover how we can support your specific needs.
            </p>
          </div>
        </section>
        <section id="sectors" className="py-5">
          <div className="container">
            <h2 className="text-center mb-4">Sectors We Serve</h2>
            <div className="row text-center">
              <div className="col-md-4 sector-item">
                <h5>UPS & Inverter</h5>
              </div>
              <div className="col-md-4 sector-item">
                <h5>AGV & MHE</h5>
              </div>
              <div className="col-md-4 sector-item">
                <h5>Defence</h5>
              </div>
              <div className="col-md-4 sector-item">
                <h5>EV 2 Wheeler</h5>
              </div>
              <div className="col-md-4 sector-item">
                <h5>Telecom</h5>
              </div>
              <div className="col-md-4 sector-item">
                <h5>EV 3 Wheeler</h5>
              </div>
              <div className="col-md-4 sector-item">
                <h5>Railway</h5>
              </div>
              <div className="col-md-4 sector-item">
                <h5>Solar & Portable</h5>
              </div>
              <div className="col-md-4 sector-item">
                <h5>Robotics</h5>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="py-5 bg-light">
          <div className="container">
            <h2 className="text-center mb-4">Get in Touch</h2>
            <p>
              Contact us for inquiries about our products and services or to get
              a quote for your custom battery needs.
            </p>
            <p>Email us at pranav@transcent.in</p>
            <Nav.Link as={Link} to="/login">
              Login
            </Nav.Link>
          </div>
        </section>
      </main>
      <footer className="text-center py-3 bg-light">
        <p>&copy; 2023 Transcent Labs. All rights reserved.</p>
      </footer>
      <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"></script>
      <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"></script>
    </div>
  );
}

export default Home;
