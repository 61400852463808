import React, { useState } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import UserProfile from "./UserProfile";
import CellData from "./CellData";
import CustomerDetails from "./CustomerDetails";
import SubmittedForms from "./SubmittedForms";
import "./Dashboard.css";

const Dashboard = () => {
  const [activeSection, setActiveSection] = useState("CellData");

  const renderSection = () => {
    switch (activeSection) {
      case "CellData":
        return <CellData />;
      case "CustomerForm":
        return <CustomerDetails />;
      case "SubmittedForms":
        return <SubmittedForms />;
      default:
        return <CellData />;
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={2} className="sidebar bg-dark text-white">
          <Nav className="flex-column">
            <h5 className="text-white">Dashboard</h5>
            <UserProfile />
            <Nav.Link
              className="text-white"
              onClick={() => setActiveSection("CellData")}
            >
              CellData
            </Nav.Link>
            <Nav.Link
              className="text-white"
              onClick={() => setActiveSection("CustomerForm")}
            >
              Customer Form
            </Nav.Link>
            <Nav.Link
              className="text-white"
              onClick={() => setActiveSection("SubmittedForms")}
            >
              Submitted Forms
            </Nav.Link>
          </Nav>
        </Col>

        <Col md={10} className="content">
          {renderSection()}
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
