import React, { useState } from "react";
import { Form, Button, Container, Card, Row, Col } from "react-bootstrap";
import axios from "axios";
import "./CustomerDetails.css"; // Include your custom styles

const StepIndicator = ({ currentStep }) => (
  <div className="step-indicator mb-4">
    <span className={`step ${currentStep >= 1 ? "completed" : ""}`}>1</span>
    <span className="line"></span>
    <span className={`step ${currentStep >= 2 ? "completed" : ""}`}>2</span>
    <span className="line"></span>
    <span className={`step ${currentStep >= 3 ? "completed" : ""}`}>3</span>
    <span className="line"></span>
    <span className={`step ${currentStep >= 4 ? "completed" : ""}`}>4</span>
    <span className="line"></span>
    <span className={`step ${currentStep >= 5 ? "completed" : ""}`}>5</span>
  </div>
);

const ApplicationTypeInfo = ({ formData, setFormData }) => (
  <div>
    <h3>Application-Specific Requirements</h3>
    <Form.Group as={Row} controlId="formApplicationType" className="mb-3">
      <Form.Label column sm="3">
        Application Type
      </Form.Label>
      <Col sm="9">
        <Form.Control
          as="select"
          name="applicationType"
          value={formData.applicationType}
          onChange={(e) =>
            setFormData({ ...formData, applicationType: e.target.value })
          }
          required
        >
          <option value="">Select</option>
          <option value="Vehicle">Vehicle</option>
          <option value="Industrial">Industrial</option>
          <option value="Consumer Electronics">Consumer Electronics</option>
        </Form.Control>
      </Col>
    </Form.Group>

    {formData.applicationType === "Vehicle" && (
      <div>
        <Form.Group as={Row} controlId="formVehicleWeight" className="mb-3">
          <Form.Label column sm="3">
            Vehicle Weight without Battery (kg)
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="number"
              placeholder="Enter weight"
              name="vehicleWeight"
              value={formData.vehicleWeight}
              onChange={(e) =>
                setFormData({ ...formData, vehicleWeight: e.target.value })
              }
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formMaxSpeed" className="mb-3">
          <Form.Label column sm="3">
            Max Speed of Vehicle (km/h)
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="number"
              placeholder="Enter max speed"
              name="maxSpeed"
              value={formData.maxSpeed}
              onChange={(e) =>
                setFormData({ ...formData, maxSpeed: e.target.value })
              }
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formTyreSize" className="mb-3">
          <Form.Label column sm="3">
            Tyre Size of Vehicle
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Enter tyre size"
              name="tyreSize"
              value={formData.tyreSize}
              onChange={(e) =>
                setFormData({ ...formData, tyreSize: e.target.value })
              }
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formOperatingDays" className="mb-3">
          <Form.Label column sm="3">
            Vehicle Operating Days/Year
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="number"
              placeholder="Enter operating days"
              name="operatingDays"
              value={formData.operatingDays}
              onChange={(e) =>
                setFormData({ ...formData, operatingDays: e.target.value })
              }
              required
            />
          </Col>
        </Form.Group>
      </div>
    )}

    {formData.applicationType === "Industrial" && (
      <div>
        <Form.Group
          as={Row}
          controlId="formOperatingConditions"
          className="mb-3"
        >
          <Form.Label column sm="3">
            Operating Conditions
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Describe operating conditions"
              name="operatingConditions"
              value={formData.operatingConditions}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  operatingConditions: e.target.value,
                })
              }
              required
            />
          </Col>
        </Form.Group>

        <Form.Group
          as={Row}
          controlId="formEnergyRequirements"
          className="mb-3"
        >
          <Form.Label column sm="3">
            Energy Requirements (kWh)
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="number"
              placeholder="Enter energy requirements"
              name="energyRequirements"
              value={formData.energyRequirements}
              onChange={(e) =>
                setFormData({ ...formData, energyRequirements: e.target.value })
              }
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formSafetyStandards" className="mb-3">
          <Form.Label column sm="3">
            Safety Standards
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="List safety standards"
              name="safetyStandards"
              value={formData.safetyStandards}
              onChange={(e) =>
                setFormData({ ...formData, safetyStandards: e.target.value })
              }
              required
            />
          </Col>
        </Form.Group>
      </div>
    )}

    {formData.applicationType === "Consumer Electronics" && (
      <div>
        <Form.Group as={Row} controlId="formDeviceType" className="mb-3">
          <Form.Label column sm="3">
            Device Type
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Describe device type"
              name="deviceType"
              value={formData.deviceType}
              onChange={(e) =>
                setFormData({ ...formData, deviceType: e.target.value })
              }
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formEnergyDensity" className="mb-3">
          <Form.Label column sm="3">
            Energy Density (Wh/kg)
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="number"
              placeholder="Enter energy density"
              name="energyDensity"
              value={formData.energyDensity}
              onChange={(e) =>
                setFormData({ ...formData, energyDensity: e.target.value })
              }
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formChargingCycles" className="mb-3">
          <Form.Label column sm="3">
            Charging Cycles
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="number"
              placeholder="Enter charging cycles"
              name="chargingCycles"
              value={formData.chargingCycles}
              onChange={(e) =>
                setFormData({ ...formData, chargingCycles: e.target.value })
              }
              required
            />
          </Col>
        </Form.Group>
      </div>
    )}
  </div>
);

const GeneralBatteryInfo = ({ formData, setFormData }) => (
  <div>
    <h3>General Battery Information</h3>
    <Form.Group as={Row} controlId="formBatteryType" className="mb-3">
      <Form.Label column sm="3">
        Battery Type
      </Form.Label>
      <Col sm="9">
        <Form.Control
          as="select"
          name="batteryType"
          value={formData.batteryType}
          onChange={(e) =>
            setFormData({ ...formData, batteryType: e.target.value })
          }
          required
        >
          <option value="">Select</option>
          <option value="Lithium Iron Phosphate">Lithium Iron Phosphate</option>
          <option value="Nickel-Metal Hydride">Nickel-Metal Hydride</option>
        </Form.Control>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="formNominalVoltage" className="mb-3">
      <Form.Label column sm="3">
        Nominal Voltage (V)
      </Form.Label>
      <Col sm="9">
        <Form.Control
          type="number"
          placeholder="Enter nominal voltage"
          name="nominalVoltage"
          value={formData.nominalVoltage}
          onChange={(e) =>
            setFormData({ ...formData, nominalVoltage: e.target.value })
          }
          required
        />
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="formCapacity" className="mb-3">
      <Form.Label column sm="3">
        Capacity (Ah)
      </Form.Label>
      <Col sm="9">
        <Form.Control
          type="number"
          placeholder="Enter capacity"
          name="capacity"
          value={formData.capacity}
          onChange={(e) =>
            setFormData({ ...formData, capacity: e.target.value })
          }
          required
        />
      </Col>
    </Form.Group>
  </div>
);

const SafetyCertificationInfo = ({ formData, setFormData }) => (
  <div>
    <h3>Safety and Certification Requirements</h3>
    <Form.Group as={Row} controlId="formComplianceStandards" className="mb-3">
      <Form.Label column sm="3">
        Compliance Standards
      </Form.Label>
      <Col sm="9">
        <Form.Control
          as="select"
          name="complianceStandards"
          value={formData.complianceStandards}
          onChange={(e) =>
            setFormData({ ...formData, complianceStandards: e.target.value })
          }
          required
        >
          <option value="">Select</option>
          <option value="ISO">ISO</option>
          <option value="UL">UL</option>
          <option value="CE">CE</option>
        </Form.Control>
      </Col>
    </Form.Group>

    <Form.Group
      as={Row}
      controlId="formCertificationDocuments"
      className="mb-3"
    >
      <Form.Label column sm="3">
        Certification of Components
      </Form.Label>
      <Col sm="9">
        <Form.Control
          type="file"
          name="certificationDocuments"
          onChange={(e) =>
            setFormData({
              ...formData,
              certificationDocuments: e.target.files[0],
            })
          }
          required
        />
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="formIPLevel" className="mb-3">
      <Form.Label column sm="3">
        IP Level
      </Form.Label>
      <Col sm="9">
        <Form.Control
          type="text"
          placeholder="Enter IP level"
          name="ipLevel"
          value={formData.ipLevel}
          onChange={(e) =>
            setFormData({ ...formData, ipLevel: e.target.value })
          }
          required
        />
      </Col>
    </Form.Group>
  </div>
);

const AdditionalSpecsInfo = ({ formData, setFormData }) => (
  <div>
    <h3>Additional Specifications</h3>
    <Form.Group
      as={Row}
      controlId="formEnvironmentalConditions"
      className="mb-3"
    >
      <Form.Label column sm="3">
        Environmental Conditions
      </Form.Label>
      <Col sm="9">
        <Form.Control
          type="text"
          placeholder="Enter temperature ranges"
          name="environmentalConditions"
          value={formData.environmentalConditions}
          onChange={(e) =>
            setFormData({
              ...formData,
              environmentalConditions: e.target.value,
            })
          }
          required
        />
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="formChargingTime" className="mb-3">
      <Form.Label column sm="3">
        Charging Time (hrs)
      </Form.Label>
      <Col sm="9">
        <Form.Control
          type="number"
          placeholder="Enter charging time"
          name="chargingTime"
          value={formData.chargingTime}
          onChange={(e) =>
            setFormData({ ...formData, chargingTime: e.target.value })
          }
          required
        />
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="formProtectionFeatures" className="mb-3">
      <Form.Label column sm="3">
        Protection Features
      </Form.Label>
      <Col sm="9">
        <Form.Check
          type="checkbox"
          label="Overcharge Protection"
          name="overchargeProtection"
          checked={formData.overchargeProtection}
          onChange={(e) =>
            setFormData({ ...formData, overchargeProtection: e.target.checked })
          }
        />
        <Form.Check
          type="checkbox"
          label="Thermal Shutdown"
          name="thermalShutdown"
          checked={formData.thermalShutdown}
          onChange={(e) =>
            setFormData({ ...formData, thermalShutdown: e.target.checked })
          }
        />
      </Col>
    </Form.Group>
  </div>
);

const CustomerDetails = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    applicationType: "", // String (Vehicle, Industrial, Consumer Electronics)
    batteryType: "", // String (Lithium Iron Phosphate, Nickel-Metal Hydride, Other)
    nominalVoltage: "", // Number
    capacity: "", // Number

    vehicleWeight: "", // Number (required if applicationType is Vehicle)
    maxSpeed: "", // Number (required if applicationType is Vehicle)
    tyreSize: "", // String (required if applicationType is Vehicle)
    operatingDays: "", // Number (required if applicationType is Vehicle)

    operatingConditions: "", // String (required if applicationType is Industrial)
    energyRequirements: "", // Number (required if applicationType is Industrial)
    safetyStandards: "", // String (required if applicationType is Industrial)

    deviceType: "", // String (required if applicationType is Consumer Electronics)
    energyDensity: "", // Number (required if applicationType is Consumer Electronics)
    chargingCycles: "", // Number (required if applicationType is Consumer Electronics)

    complianceStandards: "", // String
    certificationDocuments: null, // File (null initially, will be populated with file input)
    ipLevel: "", // String
    environmentalConditions: "", // String
    chargingTime: "", // Number
    overchargeProtection: false, // Boolean (checkbox)
    thermalShutdown: false, // Boolean (checkbox)
  });

  const token = localStorage.getItem("userToken");
  const handleSubmit = (e) => {
    e.preventDefault();

    // Initialize FormData object
    const formDataSerialized = new FormData();

    // Append text fields to the FormData

    formDataSerialized.append("applicationType", formData.applicationType);
    formDataSerialized.append("batteryType", formData.batteryType);
    formDataSerialized.append("nominalVoltage", formData.nominalVoltage);
    formDataSerialized.append("capacity", formData.capacity);
    formDataSerialized.append("vehicleWeight", formData.vehicleWeight);
    formDataSerialized.append("maxSpeed", formData.maxSpeed);
    formDataSerialized.append("tyreSize", formData.tyreSize);
    formDataSerialized.append("operatingDays", formData.operatingDays);
    formDataSerialized.append(
      "operatingConditions",
      formData.operatingConditions
    );
    formDataSerialized.append(
      "energyRequirements",
      formData.energyRequirements
    );
    formDataSerialized.append("safetyStandards", formData.safetyStandards);
    formDataSerialized.append("deviceType", formData.deviceType);
    formDataSerialized.append("energyDensity", formData.energyDensity);
    formDataSerialized.append("chargingCycles", formData.chargingCycles);
    formDataSerialized.append(
      "complianceStandards",
      formData.complianceStandards
    );
    formDataSerialized.append("ipLevel", formData.ipLevel);
    formDataSerialized.append(
      "environmentalConditions",
      formData.environmentalConditions
    );
    formDataSerialized.append("chargingTime", formData.chargingTime);
    formDataSerialized.append(
      "overchargeProtection",
      formData.overchargeProtection
    );
    formDataSerialized.append("thermalShutdown", formData.thermalShutdown);

    // Append file to the FormData if it exists
    if (formData.certificationDocuments) {
      formDataSerialized.append(
        "certificationDocuments",
        formData.certificationDocuments,
        formData.certificationDocuments.name
      );
    }

    // Use Axios to send a POST request
    axios
      .post("/api/customers", formDataSerialized, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Success:", response.data);

        // Handle success such as redirecting to a success page or displaying a success message
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors, e.g., displaying error messages to the user
      });
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <ApplicationTypeInfo formData={formData} setFormData={setFormData} />
        );

      case 2:
        return (
          <GeneralBatteryInfo formData={formData} setFormData={setFormData} />
        );
      case 3:
        return (
          <SafetyCertificationInfo
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 4:
        return (
          <AdditionalSpecsInfo formData={formData} setFormData={setFormData} />
        );

      case 5:
        return <Review formData={formData} />;
      default:
        return (
          <ApplicationTypeInfo formData={formData} setFormData={setFormData} />
        );
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Card style={{ width: "60%" }} className="p-5">
        <Card.Body>
          <h2 className="text-center mb-4">Battery Specification Form</h2>
          <StepIndicator currentStep={step} />
          <Form onSubmit={handleSubmit}>
            {renderStep()}

            <div className="text-center">
              {step > 1 && (
                <Button variant="secondary" onClick={prevStep} className="mr-2">
                  Previous
                </Button>
              )}
              {step < 5 && (
                <Button variant="primary" onClick={nextStep}>
                  Next
                </Button>
              )}
              {step === 5 && (
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

const Review = ({ formData }) => (
  <div>
    <h3>Review Your Information</h3>
    <ul>
      {Object.keys(formData).map((key) => (
        <li key={key}>
          <strong>{key}:</strong> {formData[key]?.toString()}
        </li>
      ))}
    </ul>
  </div>
);

export default CustomerDetails;
